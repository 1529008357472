<template>
  <jobs-list />
</template>

<script>
import JobsList from './JobsList.vue'

export default {
  components: {
    JobsList,
  },
}
</script>

<style></style>
